export * from 'SourceComponent/ProductActions/ProductActions.config';

export const DEFAULT_MAX_PRODUCTS = 99;
export const ONE_HUNDRED_PERCENT = 100;
export const SAVING_PROMO_BLOCK = 'saving-promo-block';
export const SAVING_PROMO_BLOCK_APPLICABLE_TYPES = [
    'DOT MATRIX',
    'FAX MACHINE',
    'LABEL PRINTER',
    'LARGE FORMAT PRINTER',
    'MOBILE PRINTER',
    'MULTIFUNCTION',
    'PHOTO PRINTER',
    'PRINTERS'
];

// eslint-disable-next-line array-func/prefer-array-from
export const LOOKING_FOR_CARTRIDGES_APPLICABLE_TYPES = [
    ...SAVING_PROMO_BLOCK_APPLICABLE_TYPES
];

export const SUBSCRIPTION_POPUP = 'subscription_popup';
export const SUBSCRIPTION_POPUP_EPSON = 'subscription_popup_epson';
export const SUBSCRIPTION_POPUP_LEXMARK = 'subscription_popup_lexmark';
export const SUBSCRIPTION_POPUP_HP = 'subscription_popup_hp';
export const SUBSCRIPTION_POPUP_AND_BANNER_HP = 'subscription_popup_and_banner_hp';

export const HARDWARE_SET = 'Hardware Set';
export const STOCK_NOTIFY_POPUP = 'stock_notify_popup';
export const CALLBACK_REQUEST_POPUP = 'callback_request_popup';
export const REQUEST_DETAILS_POPUP = 'request_details_popup';
