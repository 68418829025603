import Html from 'Component/Html';
import {
    ProductPriceComponent as SourceProductPriceComponent
} from 'SourceComponent/ProductPrice/ProductPrice.component';

import {
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX,
    DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX
} from './ProductPrice.config';

import './ProductPrice.style.override.scss';

/**
 * Product price
 * @class ProductPrice
 * @namespace Scandipwa/Component/ProductPrice/Component */
export class ProductPriceComponent extends SourceProductPriceComponent {
    /**
     * Overridden to add the tag inc. tax
     */
    renderPrice(price, label) {
        const {
            discountPercentage
        } = this.props;

        const {
            originalPrice: {
                minFinalPrice: { valueFormatted: originalPriceFormatted } = {}
            } = {}
        } = this.props;

        const {
            value: priceValue,
            valueFormatted: priceFormatted = 0
        } = price;

        const { itemProp = null, content = null } = this.getCurrentPriceSchema();

        // Use <ins></ins> <del></del> to represent new price and the old (deleted) one
        const PriceSemanticElementName = discountPercentage > 0 ? 'ins' : 'span';

        // force unequal comparison - unsure of resulting type
        // eslint-disable-next-line
        if (priceValue == 0) {
            return null;
        }

        return (
            <PriceSemanticElementName block="ProductPrice" elem="Price">
                { this.renderPriceBadge(label) }
                <span
                  itemProp={ itemProp }
                  content={ content }
                  block="ProductPrice"
                  elem="PriceValue"
                >
                    { originalPriceFormatted || priceFormatted }
                    <span
                      block="ProductPrice"
                      elem="PriceTag"
                    >
                        { __('inc VAT') }
                    </span>
                </span>
            </PriceSemanticElementName>
        );
    }

    /**
     * Overridden to style the same as price
     */
    renderSubPrice(price) {
        const {
            value: priceExclTax = 0,
            valueFormatted: priceExclTaxFormatted = 0
        } = price;

        if (!priceExclTax) {
            return null;
        }

        return (
            <span
              aria-label={ __('Current product price excl. tax') }
              block="ProductPrice"
              elem="PriceValue"
            >
                { priceExclTaxFormatted }
                <span
                  block="ProductPrice"
                  elem="PriceTag"
                >
                    { __('ex VAT') }
                </span>
            </span>
        );
    }

    /**
     * Overridden to render exc. tax before inc. tax
     */
    renderPriceWithOrWithoutTax(basePrice, taxPrice, label) {
        const { displayTaxInPrice } = this.props;

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_INCL_TAX) {
            return this.renderPrice(basePrice, label);
        }

        if (displayTaxInPrice === DISPLAY_PRODUCT_PRICES_IN_CATALOG_EXCL_TAX) {
            return this.renderPrice(taxPrice, label);
        }

        return (
            <div block="ProductPrice" elem="Prices">
                { this.renderSubPrice(taxPrice) }
                { this.renderPrice(basePrice, label) }
            </div>
        );
    }

    renderOldPriceBuilder() {
        const { getOldPriceHtml } = this.props;
        const data = getOldPriceHtml();

        if (!data) {
            return null;
        }

        const result = data.isHtml
            ? (
                <div>
                    <Html content={ data.text } />
                </div>
            )
            : (
                <del>{ data.text }</del>
            );

        return (
            <div
              block="ProductPrice"
              elem="SpecialPrice"
              mods={ { isDefault: !data.isHtml } }
              aria-label={ __('Old product price') }
            >
                { result }
            </div>
        );
    }

    renderOldPrice() {
        const { isMobile, simpleOldPrice, hideMobileOldPrice } = this.props;

        if (isMobile && hideMobileOldPrice) {
            return null;
        }

        if (!simpleOldPrice) {
            return this.renderOldPriceBuilder();
        }

        return super.renderOldPrice();
    }

    renderDefaultPrice(defaultLabel = null) {
        const {
            price: { finalPrice = {}, finalPriceExclTax = {} } = {},
            label
        } = this.props;

        return (
            <>
                { this.renderOldPrice() }
                { this.renderPriceWithOrWithoutTax(finalPrice, finalPriceExclTax, defaultLabel || label) }
                { this.renderSchema() }
            </>
        );
    }

    render() {
        const { renderOnlyOldPrice } = this.props;

        if (renderOnlyOldPrice) {
            return this.renderOldPrice();
        }

        return super.render();
    }
}

export default ProductPriceComponent;
